import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { PrimaryButton } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/contact_us.jpg";
import axios from 'axios'

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto my-10 md:my-12`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-6/12 relative `;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = tw.img`rounded bg-contain bg-no-repeat bg-center`;

const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({ as: "textarea" })`
  ${tw`h-24`}
`

export default ({
  imageAlt = "Contact-US-Polygon3D",
  imageSrc = EmailIllustrationSrc,
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr /> with us.</>,
  description = "",
  submitButtonText = "Send",
  formAction = "/contact",
  formMethod = "post",
  textOnLeft = true,
  id = "contact-us"
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
const [email,setEmail]=React.useState('')
const [name,setName]=React.useState('')
const [subject,setSubject]=React.useState('')
const [message,setMessage]=React.useState('')
async function onSubmit(e){
  e.preventDefault();
  console.log(email)
  try {
    const send = await axios.post(`/api/contact`, {email,name,subject,message});
    if(send){
     alert('sent successfully');
    }
  } catch (error) {
    alert('Could not send email, Please try again')
    throw error
  }

}
  return (

    <Container id={id}>
      <TwoColumn>
        <ImageColumn>
          <Image src={imageSrc} alt={imageAlt} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form onSubmit={onSubmit}>
              <Input type="email" name="email" placeholder="Your Email Address" onChange={(t)=>{setEmail(t.target.value)}} />
              <Input type="text" name="name" placeholder="Full Name" onChange={(t)=>{setName(t.target.value)}}/>
              <Input type="text" name="subject" placeholder="Subject" onChange={(t)=>{setSubject(t.target.value)}} />
              <Textarea name="message" placeholder="Your Message Here"  onChange={(t)=>{setMessage(t.target.value)}}/>
              <PrimaryButton type="submit">{submitButtonText}</PrimaryButton>

              <div>
                {window.location.hash === '#success' &&
                  <div id="success">
                    <p>Your message has been sent!</p>
                  </div>
                }
                {window.location.hash === '#error' &&
                  <div id="error">
                    <p>An error occured while submitting the form.</p>
                  </div>
                }
              </div>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
